<template>
  <div style="padding: 20px">
    <el-table :data="list" border stripe>
      <el-table-column prop="name" label="用户"></el-table-column>
      <el-table-column prop="min_rate" label="著作权许可费率">
        <template slot-scope="scope">
          <span>{{ scope.row | formatFee }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="desc" label="备注"></el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEditFee(scope.row)">
            编辑费率
          </el-button>
          <el-button size="mini" type="primary" @click="handleEditRemark(scope.row)">
            编辑备注
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="visible" width="30%" @close="handleCloseDialog">
      <div>
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="最小费率" prop="min_rate">
            <el-input v-model="form.min_rate"></el-input>
          </el-form-item>
          <el-form-item label="最大费率" prop="max_rate">
            <el-input v-model="form.max_rate"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button>取消</el-button>
            <el-button type="primary" @click="handleSubmit">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCopyrightLicenseRateList, updateCopyrightLicenseRate } from "@/api/nft";

export default {
  data() {
    // const checkInt = (rule, value, callback) => {

    //    const isPositiveInt = /^[1-9]\d*$/;
    //   if (!isPositiveInt.test(this.priorityQuota)) {
    //     this.$message.error("优先购名额必须为正整数");
    //     callback(new Error("请输入正整数"));
    //     return;
    //   }
    //   if (value === "") {
    //     callback(new Error("请输入排序编号"));
    //   } else if (+value < 0 || +value > 99) {
    //     callback(new Error("排序编号为0-99的整数"));
    //   }
    //   callback();
    // };

    return {
      list: [
        {
          id: "62d4d16969e83456af6cfc3c",
          key: "ordinaryUser",
          name: "普通用户", // 名称
          desc: "非艺术家用户群体", // 备注
          min_rate: "0", // 最小费率
          max_rate: "3", // 最大费率
          created_at: 1658113906,
          updated_at: 0,
        },
      ],
      visible: false,
      form: {},
      rules: {
        min_rate: [
          { required: true, message: "请输入最小费率", trigger: "blur" },
          // { validator: checkInt, trigger: "blur" },
        ],
        max_rate: [
          { required: true, message: "请输入最大费率", trigger: "blur" },
          // { validator: checkInt, trigger: "blur" },
        ],
      },
    };
  },
  filters: {
    formatFee: function(value) {
      const { min_rate, max_rate } = value;
      if (min_rate === max_rate) {
        return min_rate + "%";
      }
      return `${min_rate}-${max_rate}%`;
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const { data } = await getCopyrightLicenseRateList();
        this.list = data.data.items;
      } catch (error) {
        console.log(error);
        this.list = [];
      }
    },
    async updateItem() {
      try {
        const params = {
          id: this.form.id,
          max_rate: this.form.max_rate,
          min_rate: this.form.min_rate,
        };
        const { data } = await updateCopyrightLicenseRate(params);
        console.log(data);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    handleEditFee(data) {
      this.handleOpenDialog(data);
    },
    handleEditRemark() {
      this.$message.info("TODO");
    },
    handleCloseDialog() {
      this.visible = false;
      this.form = {};
    },
    handleOpenDialog(data) {
      this.visible = true;
      this.form = {
        id: data.id,
        min_rate: data.min_rate,
        max_rate: data.max_rate,
      };
    },

    handleSubmit() {
      console.log(this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },

    async submit() {
      const { min_rate, max_rate } = this.form;
      if (+min_rate > +max_rate) {
        this.$message.error("最小费率不能大于最大费率");
        return;
      }
      if (+min_rate > 10 || +max_rate > 10) {
        this.$message.error("费率不能超过10%");
        return;
      }
      const isSuccess = await this.updateItem();
      if (isSuccess) {
        this.fetchList();
        this.$message.success("修改成功");
        this.handleCloseDialog();
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
